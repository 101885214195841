<template>
  <div>
    <!-- TODO: Validar pra trocar por BcModal -->
    <bc-dialog v-model="dialog" :width="width">
      <bc-card class="modal">
        <div class="modal-header">
          <bc-card-title>
            {{ $t('application.pages.scenarioCargill.importTitle') }}
          </bc-card-title>
        </div>
        <div class="container-modal">
          <bc-card-text>
            <div class="default">
              <bc-dropzone
                v-model="files"
                :accept="['.supplybrain']"
                :title="
                  $t(
                    'application.pages.scenarioCargill.importSelectDescription'
                  )
                "
                :action-label="$t('core.import.select.button')"
                height="600px"
              />
            </div>
          </bc-card-text>
        </div>

        <bc-card-actions class="modal-footer">
          <bc-spacer></bc-spacer>
          <bc-btn
            class="v-btn v-btn--has-bg theme--dark elevation-0 v-size--default link-blue"
            text
            @click="cancel()"
          >
            {{ $t('core.misc.cancel') }}
          </bc-btn>
          <bc-btn
            class="v-btn v-btn--has-bg theme--dark elevation-0 v-size--default primary"
            :disabled="!validate()"
            text
            @click="importScenario()"
          >
            {{ $t('application.pages.scenarioCargill.import') }}
          </bc-btn>
        </bc-card-actions>
      </bc-card>
    </bc-dialog>
  </div>
</template>

<script>
import {
  BcDialog,
  BcBtn,
  BcCard,
  BcCardTitle,
  BcCardText,
  BcCardActions,
  BcSpacer,
  BcDropzone
} from '@brain/core'

export default {
  name: 'ScenarioImportModal',
  components: {
    BcDialog,
    BcBtn,
    BcCard,
    BcCardTitle,
    BcCardText,
    BcCardActions,
    BcSpacer,
    BcDropzone
  },
  props: {
    show: {
      default: false,
      type: Boolean
    },
    width: {
      default: '55rem',
      type: String
    },
    service: {
      required: true
    }
  },
  data() {
    return {
      files: [],
      dialog: false
    }
  },
  methods: {
    validate() {
      return this.files.length > 0
    },
    getFiles() {
      return this.files
    },
    async importScenario() {
      try {
        await this.submitFile(this.getFile())
        this.notify.success({
          title: this.$t(
            'application.pages.scenarioCargill.scenarioIsBeingImported'
          ),
          description: this.$t(
            'application.pages.scenarioCargill.scenarioIsBeingImportedDescription'
          )
        })
        this.$emit('scenarioImported')
      } catch (e) {
        console.log(e)
      }
      this.dialog = false
    },
    async submitFile(file) {
      if (file == null || this.service == null) return null
      const formData = new FormData()
      formData.append('scenarioFile', file)
      return await this.service.importScenario(formData)
    },
    getFile() {
      return this.files.length > 0 ? this.files[0] : null
    },
    cancel() {
      this.dialog = false
    }
  },
  watch: {
    show: function () {
      if (this.dialog != this.show) {
        this.dialog = this.show
        this.files = []
      }
    },
    dialog: function () {
      this.$emit('input', this.dialog)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__text {
  padding: 0;
}
.v-dialog > .v-card > .v-card__title {
  padding: 16px 0px 16px;
}
.container-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  background-color: #262e39;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 4px;
}
.v-dialog .v-card .modal-footer {
  padding: 8px 0 8px 0;
}
.modal-header {
  background-color: #262e39;
}
.modal-header .v-card__title {
  color: rgb(149, 157, 181);
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  padding: 12px 20px 12px 20px;
}

.modal.v-card.brain {
  padding: 20px 20px 12px 20px;
}
</style>
