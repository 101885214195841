<template>
  <div class="scenario-wrapper">
    <div class="button-header-container" v-if="mode == 'list'">
      <bc-btn color="primary" @click="create" v-show="canCreate">
        {{ $t('application.pages.scenarioCargill.createScenario') }}
      </bc-btn>
    </div>
    <div v-if="mode == 'create'" class="create-wrapper">
      <section id="steps">
        <v-stepper v-model="step" alt-labels class="bg-panel">
          <!-- HEADERS -->
          <v-stepper-header>
            <v-stepper-step :complete="step > 1" step="1">
              {{ $t('application.pages.scenarioCargill.title') }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 2" step="2">
              {{ $t('application.pages.scenarioCargill.horizon') }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="step > 3" step="3">
              {{ $t('application.pages.scenarioCargill.confirmation') }}
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="4">
              {{ $t('application.pages.scenarioCargill.createScenario') }}
            </v-stepper-step>
          </v-stepper-header>

          <!-- ITEMS -->
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="bg-card">
                <scenario-name-step
                  v-if="step == 1"
                  :errorMessages="errorMessages"
                  @clearErrors="errorMessages = []"
                ></scenario-name-step>
              </v-card>

              <action-buttons
                :next="$t('application.actions.next')"
                :back="$t('application.actions.back')"
                :cancel="$t('application.actions.cancel')"
                @next="validate"
                @cancel="list"
                @back="list"
              ></action-buttons>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-card class="bg-card">
                <scenario-horizon-step
                  v-if="step == 2"
                  :errorMessages="fromScenarioErrors"
                  @clearErrors="fromScenarioErrors = []"
                ></scenario-horizon-step
              ></v-card>

              <action-buttons
                :next="$t('application.actions.next')"
                :back="$t('application.actions.back')"
                :cancel="$t('application.actions.cancel')"
                @next="validate"
                @cancel="cancel"
                @back="back"
              ></action-buttons>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-card class="bg-card">
                <scenario-create-step v-if="step == 3"></scenario-create-step>
              </v-card>

              <action-buttons
                :next="$t('application.actions.create')"
                :back="$t('application.actions.back')"
                :cancel="$t('application.actions.cancel')"
                @next="list"
                @cancel="cancel"
                @back="back"
              ></action-buttons>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-card class="bg-card padding">
                <bc-message
                  :type="createdSuccessfully ? 'warning' : 'error'"
                  v-if="scenarioStore.getScenario.name"
                  :icon="'fa-exclamation-circle'"
                  :title="
                    $t(
                      createdSuccessfully
                        ? 'application.pages.scenarioCargill.scenarioIsBeingCreated'
                        : 'application.pages.scenarioCargill.errorOnCreated',
                      { scenario: this.scenarioStore.getScenario.name }
                    )
                  "
                  :subtitle="
                    createdSuccessfully
                      ? $t(
                          'application.pages.scenarioCargill.scenarioIsBeingCreatedDescription'
                        )
                      : ''
                  "
                  :actions="[
                    {
                      type: 'button',
                      color: 'transparent',
                      label: $t(
                        'application.pages.scenarioCargill.createNewScenario'
                      ),
                      callback: create
                    },
                    {
                      type: 'button',
                      color: '#28b6e7',
                      label: $t(
                        'application.pages.scenarioCargill.scenarioList'
                      ),
                      callback: goToMainList
                    }
                  ]"
                >
                </bc-message>
              </v-card>

              <action-buttons
                :next="$t('application.actions.finish')"
                :cancel="$t('application.actions.cancel')"
                @next="goToMainList"
                @cancel="cancel"
              ></action-buttons>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </section>
    </div>

    <div v-if="metadata != null && mode == 'list'">
      <cargill-crud-meta-view
        :service="crudService"
        :metadata="metadata"
        :reloadData="reloadData"
        :gridMode="'serverSide'"
        @gridReady="onGridReady"
        @onReloadData="reloadData = false"
        translatePrefix="application.pages"
      />
    </div>

    <cargill-confirm-dialog
      v-model="showConfirmPublish"
      type="warning"
      icon="fa-exclamation-circle"
      :title="$t('application.pages.scenarioCargill.confirmPublishTitle')"
      :subtitle="$t('application.pages.scenarioCargill.confirmPublish')"
      :confirmLabel="$t('application.actions.yes')"
      :cancelLabel="$t('application.actions.no')"
      :hasCheckbox="false"
      @confirm="confirmPublish()"
      @cancel="showConfirmPublish = $event"
    ></cargill-confirm-dialog>

    <cargill-confirm-dialog
      v-model="showConfirmUnpublish"
      type="warning"
      icon="fa-exclamation-circle"
      :title="$t('application.pages.scenarioCargill.confirmUnpublishTitle')"
      :subtitle="$t('application.pages.scenarioCargill.confirmUnpublish')"
      :confirmLabel="$t('application.actions.yes')"
      :cancelLabel="$t('application.actions.no')"
      :hasCheckbox="false"
      @confirm="confirmUnpublish()"
      @cancel="showConfirmUnpublish = $event"
    ></cargill-confirm-dialog>

    <scenario-import-dialog
      :show="showImportDialog"
      :service="getService()"
      @scenarioImported="onScenarioImported()"
      @input="showImportDialog = $event"
    />
  </div>
</template>

<script>
import {
  ScenarioCreateStep,
  ScenarioNameStep,
  ScenarioHorizonStep,
  ScenarioImportDialog
} from '../components/scenario'
import ActionButtons from '../components/action-buttons/ActionButtons.vue'
import { CrudAction } from '@brain/core'
import CargillConfirmDialog from '../components/confirm-dialog/CargillConfirmDialog.vue'
import {
  CargillCrudMetaView,
  helpers,
  scenarioStore,
  useScenarioOpen,
  gridUtils,
  eventHub,
  userSettingsService,
  useModuleOpen,
  scenarioCargillService as service
} from '@cargill/shared'
import FileDownload from 'js-file-download'
import _ from 'lodash'
import moment from 'moment'

const { getModuleOpen } = useModuleOpen()
const { getScenarioOpen, updateScenarioOpen } = useScenarioOpen()

export default {
  components: {
    CargillCrudMetaView,
    ScenarioCreateStep,
    ScenarioNameStep,
    ScenarioHorizonStep,
    ActionButtons,
    CargillConfirmDialog,
    ScenarioImportDialog
  },
  data() {
    return {
      metadata: null,
      crudService: service,
      step: 1,
      mode: 'list',
      showConfirmPublish: false,
      showConfirmUnpublish: false,
      scenarioToPublish: null,
      reloadData: false,
      scenarioStore: null,
      errorMessages: [],
      fromScenarioErrors: [],
      showImportDialog: false,
      countDown: 10,
      canCreate: false,
      userSettings: {},
      timeOutObject: null,
      updateScenario: Function,
      createdSuccessfully: false
    }
  },
  computed: {
    currentScenario() {
      let scenario = getScenarioOpen()
      return scenario
    }
  },
  mounted() {
    this.scenarioStore = scenarioStore()
    this.getUserSettings()
    this.$notification.on(
      `${this.getCurrentModule()}Notification`,
      this.notificationUpdate
    )
  },
  beforeDestroy() {
    this.cancelCrudProcessing()
    if (this.$notification !== null) {
      this.$notification.off(
        `${this.getCurrentModule()}Notification`,
        this.notificationUpdate
      )
    }
  },
  created() {
    this.registerCrudProcessing()
    service.getMeta().then((meta) => {
      const editableFields = ['name', 'description']

      meta.fields.forEach((field) => {
        if (editableFields.includes(field.id)) {
          field.editable = true
        }
      })

      this.canCreate = meta.permissions.filter(
        (o) => o === CrudAction.CREATE
      ).length

      const removedPermissions = new Set([CrudAction.CREATE, CrudAction.EXPORT])
      meta.permissions = meta.permissions.filter(
        (p) => !removedPermissions.has(p)
      )
      var vm = this

      meta.actions = [
        {
          title: ({ node }) => {
            return node.data.published
              ? this.$t('application.pages.scenarioCargill.unpublish')
              : this.$t('application.pages.scenarioCargill.publish')
          },
          className: ({ node }) => {
            return node.data.published ? 'fas fa-globe-stand' : 'fas fa-globe'
          },
          enable: true,
          callback: ({ node }) => {
            this.publish(node.data)
          }
        },
        {
          title: ({ node }) => {
            return node.data.id != vm.currentScenario.id
              ? this.$t('application.pages.scenarioCargill.openScenario')
              : this.$t('application.pages.scenarioCargill.closeScenario')
          },
          className: ({ node }) => {
            return node.data.id != vm.currentScenario.id
              ? 'fas fa-folder-open'
              : 'fas fa-folder'
          },
          enable: true,
          callback: ({ node }) => {
            return node.data.id != vm.currentScenario.id
              ? this.openScenario(this.currentScenario, node.data)
              : this.closeScenario(this.currentScenario)
          }
        },
        {
          title: ({ node }) => {
            return node.data.public
              ? this.$t('application.pages.scenarioCargill.private')
              : this.$t('application.pages.scenarioCargill.public')
          },
          className: ({ node }) => {
            return node.data.public ? 'fas fa-eye-slash' : 'fas fa-eye'
          },
          enable: true,
          callback: ({ node }) => {
            this.onToggleScenarioPublic(node.data)
          }
        },
        {
          title: this.$t('application.pages.scenarioCargill.export'),
          className: 'fas fa-download',
          enable: true,
          callback: ({ node }) => {
            this.exportScenario(node.data)
          }
        }
      ]
      this.originalMetada = _.cloneDeep(meta)
      this.metadata = meta
    })
  },
  methods: {
    async notificationUpdate(newNotifications) {
      if (
        newNotifications.some(
          (x) => x.notificationEvent?.toUpperCase() == 'Scenario'.toUpperCase()
        )
      ) {
        this.reloadData = true
      }
    },
    getCurrentModule() {
      return _.upperFirst(getModuleOpen())
    },
    closeScenario(_currentScenario) {
      if (this.userSettings.scenarioCloseAskAgain) {
        eventHub.$emit('confirm-close-scenario', _currentScenario)
      } else {
        this._closeScenario()
      }
    },
    _closeScenario() {
      updateScenarioOpen(null)
      this.notifySucess()
    },
    registerCrudProcessing() {
      service.registerPostProcessing(
        CrudAction.DELETE,
        this.scenarioOpenAfterDelete
      )
    },
    cancelCrudProcessing() {
      service.cancelPostProcessing(
        CrudAction.DELETE,
        this.scenarioOpenAfterDelete
      )
    },
    scenarioOpenAfterDelete({ ids }) {
      const scenario = getScenarioOpen()
      if (ids.includes(scenario?.id)) {
        updateScenarioOpen(null)
        this.$router.push('/')
      }
    },
    async getUserSettings() {
      this.userSettings = await userSettingsService.get()
    },
    resetAndCancelTimer() {
      if (this.timeOutObject) {
        clearTimeout(this.timeOutObject)
        this.timeOutObject = null
      }
      this.countDown = 10
    },
    countDownTimer() {
      if (this.countDown > 0) {
        this.timeOutObject = setTimeout(() => {
          this.countDown -= 1
          this.countDownTimer()
        }, 1000)
      } else {
        this.goToMainList()
      }
    },
    getService() {
      return service
    },
    hasErrors(response) {
      return response.error
    },
    notifyErrors(error) {
      const errors = error?.error?.response?.data?.errors ?? []
      errors.forEach((error) => {
        this.notify.error({
          title: this.$t(error)
        })
      })
    },
    async validate() {
      if (this.step == 1 && this.scenarioStore.getScenario.name) {
        const scenario = _.cloneDeep(this.scenarioStore.scenario)

        scenario.beginHorizon = moment(scenario.beginHorizon).toISOString()
        scenario.endHorizon = moment(scenario.endHorizon).toISOString()
        scenario.fromScenarioId = scenario.fromScenario?.id
        const resp = await service.validadeWizard(scenario)
        if (!this.hasErrors(resp)) {
          this.next()
        } else {
          this.notifyErrors(resp)
        }
      } else if (this.step == 2) {
        if (this.scenarioStore.getScenario.scenarioSource != 'copy') {
          this.next()
        } else if (!this.scenarioStore.getScenario.fromScenario) {
          this.fromScenarioErrors.push(
            this.$t('application.validation.validateFieldRequired')
          )
          this.notify.error({
            title: this.$t('application.validation.validateFieldRequired')
          })
        } else {
          this.next()
        }
      } else if (this.step == 3) {
        this.next()
      } else {
        this.errorMessages.push(
          this.$t('application.validation.validateFieldRequired')
        )
        this.notify.error({
          title: this.$t('application.validation.validateFieldRequired')
        })
      }
    },
    next() {
      this.step++
    },
    back() {
      this.step--
    },
    cancel() {
      this.step = 1
      this.list()
    },
    create() {
      this.resetAndCancelTimer()
      this.mode = 'create'
      this.step = 1
      this.scenarioStore.resetScenario()
    },
    async list() {
      if (this.step == 3) {
        const scenario = _.cloneDeep(this.scenarioStore.scenario)
        scenario.beginHorizon = moment(scenario.beginHorizon).toISOString()
        scenario.endHorizon = moment(scenario.endHorizon).toISOString()
        scenario.fromScenarioId = scenario.fromScenario?.id
        const resp = await service.create(scenario)
        if (resp?.hasErrors) {
          this.createdSuccessfully = false
        } else {
          this.createdSuccessfully = true
          this.next()
          // this.countDownTimer()
          this.notifySucess()
        }
      } else {
        this.step = 1
        this.metadata = this.originalMetada
        this.originalMetada = _.cloneDeep(this.originalMetada)
        this.mode = 'list'
      }
    },
    goToMainList() {
      this.resetAndCancelTimer()
      this.step = 1
      this.mode = 'list'
    },
    openScenario(_currentScenario, _scenarioToOpen) {
      if (this.userSettings.scenarioAskAgain)
        eventHub.$emit(
          'confirm-open-scenario',
          _currentScenario,
          _scenarioToOpen
        )
      else this._openScenario(_scenarioToOpen)
      eventHub.$emit('update-current-scenario')

      this.reloadData = true
    },
    _openScenario(scenario) {
      updateScenarioOpen(scenario)
      this.notifySucess()
    },
    async publish(scenario) {
      this.scenarioToPublish = scenario
      this.showConfirmUnpublish = this.scenarioToPublish.published
      this.showConfirmPublish = !this.scenarioToPublish.published
    },
    async exportScenario(scenario) {
      const data = await service.exportScenario(scenario.id)
      FileDownload(data, `${scenario.name}.supplybrain`)
    },
    notifySucess() {
      this.notify.success({ title: this.$t('application.misc.success') })
    },
    notifyPublishSucess() {
      this.notify.success({
        title: this.$t(
          'application.pages.scenarioCargill.scenarioPublishingMessage'
        )
      })
    },
    async confirmPublish() {
      const resp = await service.publish(this.scenarioToPublish.id)
      if (this.hasErrors(resp)) {
        this.notifyErrors(resp)
      } else {
        this.notifyPublishSucess()
      }
      this.reloadData = true
    },
    async confirmUnpublish() {
      const resp = await service.unpublish(this.scenarioToPublish.id)
      if (this.hasErrors(resp)) {
        this.notifyErrors(resp)
      } else {
        this.notifySucess()
      }
      this.reloadData = true
    },
    async onToggleScenarioPublic(scenario) {
      const resp = await service.toggleScenarioPublic(scenario.id)
      if (this.hasErrors(resp)) {
        this.notifyErrors(resp)
      } else {
        scenario.public = !scenario.public
        this.notifySucess()
      }
      this.reloadData = true
    },
    getGridComponent() {
      return helpers.findFirstComponent(this, ['BcGrid', 'CgGrid'])
    },
    onGridReady() {
      const gridOptions = this.getGridComponent().aggridOptions
      const crudActionsToolbar = helpers.findComponent(
        this,
        'BcCrudActionsToolbar'
      )
      const importAction = crudActionsToolbar.actions.find(
        (action) => action.titleKey == 'core.misc.import'
      )
      importAction.onClick = () => {
        this.showImportDialog = true
      }

      gridOptions.api.setSortModel([{ colId: 'lastUpdatedDate', sort: 'desc' }])

      crudActionsToolbar.$forceUpdate()
      gridUtils.resize(gridOptions)
    },
    onScenarioImported() {
      this.reloadData = true
    }
  }
}
</script>
<style lang="scss" scoped>
.create-wrapper {
  margin: 0 25px;
}

::v-deep .padding {
  padding: 3rem;
}
::v-deep .flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
::v-deep .input-size {
  margin: 1rem 0.4rem !important;
  width: 400px;
}
::v-deep .bg-card {
  background: var(--bc-layer-2-bg) !important;
}

::v-deep .spacing {
  margin: 0 5px;
}
::v-deep .bg-panel {
  background: var(--bc-panel-bg) !important;
}
::v-deep .bg-header {
  background-color: var(--bc-bg-header) !important;
}
::v-deep .ag-ext-action-btn {
  margin-right: 0.3rem;
}
::v-deep .content-label {
  color: var(--bc-title-color);
  font-family: var(--bc-font-regular);
  font-weight: 900;
  text-transform: uppercase;
  display: flex;
  align-content: center;
  padding: 1rem;
  background-color: var(--bc-bg-header);
  margin-top: 1.3rem;
  align-items: center;
  i {
    margin-left: 0.5rem;
  }
}
::v-deep .button-header-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10px;
}
::v-deep .top-buttons-container.buttons-container {
  display: none;
}
::v-deep .v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 27px -67px 0;
}

#crud-view__container {
  padding-top: 0 !important;
}
</style>
