<template>
  <div>
    <div class="content-label">
      <v-icon>fas fa-layer-group</v-icon>
      <div class="scenario">
        <div>{{ $t('application.pages.scenarioCargill.scenarioName') }}</div>
        <div class="name" v-if="scenario">{{ scenario.scenario.name }}</div>
      </div>
    </div>

    <div class="flex-center">
      <bc-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <bc-text-field
            class="input-size"
            v-model="startDateFormated"
            :label="$t('application.pages.scenarioCargill.start') + ' *'"
            append-icon="fa-calendar-alt"
            aria-expanded="false"
            readonly
            v-bind="attrs"
            v-on="on"
            filled
          ></bc-text-field>
        </template>
      </bc-menu>

      <bc-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <bc-text-field
            v-model="endDateFormated"
            class="input-size"
            :label="$t('application.pages.scenarioCargill.end') + ' *'"
            append-icon="fa-calendar-alt"
            readonly
            v-bind="attrs"
            v-on="on"
            filled
          ></bc-text-field>
        </template>
      </bc-menu>
    </div>
    <div class="flex-center">
      <bc-select
        filled
        class="input-size"
        v-model="scenarioType"
        :items="types"
        item-text="name"
        item-value="value"
        :menu-props="{ top: true, offsetY: true }"
        :label="$t('application.pages.scenarioCargill.type')"
      ></bc-select>
    </div>
    <div v-if="setRange">
      <div class="data-source-wrapper">
        <div class="data-source full-size">
          <div>
            <span class="select-datasource">
              {{ $t('application.pages.scenarioCargill.selectMonthlyDate') }}
            </span>
            <bc-radio-group v-model="rangeFrom" row>
              <bc-radio
                :label="$t('application.pages.scenarioCargill.MonthlyM1')"
                value="1"
              ></bc-radio>
              <bc-radio
                :label="$t('application.pages.scenarioCargill.MonthlyM2')"
                value="2"
              ></bc-radio>
            </bc-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="content-label">
      {{ $t('application.pages.scenarioCargill.dataSource') }}
    </div>
    <div class="data-source-wrapper">
      <div class="data-source half-size">
        <div>
          <span class="select-datasource">
            {{ $t('application.pages.scenarioCargill.selectSource') }}
          </span>
          <bc-radio-group v-model="copyFrom" row>
            <bc-radio
              :label="$t('application.pages.scenarioCargill.blank')"
              :value="'blank'"
            ></bc-radio>
            <bc-radio
              :label="$t('application.pages.scenarioCargill.copy')"
              value="copy"
            ></bc-radio>
            <bc-radio
              :label="$t('application.pages.scenarioCargill.master')"
              value="master"
            ></bc-radio>
          </bc-radio-group>
        </div>
      </div>
      <div class="half-size middle-margin" v-show="copyFrom == 'copy'">
        <bc-autocomplete
          filled
          v-model="fromScenario"
          model-value="fromScenario"
          :items="scenarios"
          :menu-props="{ top: true, offsetY: true }"
          :label="$t('application.pages.scenarioCargill.title') + ' *'"
          item-text="name"
          :error-messages="errorMessages"
          :return-object="true"
          @input="$emit('clearErrors')"
        ></bc-autocomplete>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import {
  scenarioStore,
  scenarioCargillType as scenarioTypes,
  scenarioCargillService as service
} from '@cargill/shared'

export default {
  name: 'ScenarioNameStep',
  props: {
    errorMessages: {
      type: Array
    }
  },
  data() {
    return {
      menu: false,
      menu2: false,
      setRange: false,
      start: null,
      end: null,
      copyFrom: '',
      rangeFrom: '',
      menuTimePickerNotFilled: false,
      types: [],
      scenarioType: null,
      fromScenario: null,
      scenarios: [],
      scenario: null,
      dateFormat: null,
      dateFormatStore: 'YYYY-MM-DD',
      dateBaseMonthly: null
    }
  },
  computed: {
    startDateFormated() {
      return this.formatDate(this.start)
    },
    endDateFormated() {
      return this.formatDate(this.end)
    }
  },
  watch: {
    start(_start) {
      this.scenario.setStart(_start)
    },
    end(_end) {
      this.scenario.setEnd(_end)
    },
    copyFrom(_copyFrom) {
      this.scenario.setSource(_copyFrom)
      if (_copyFrom != 'copy') {
        this.scenario.setFromScenario(null)
      }
    },
    rangeFrom(_rangeFrom) {
      let range = null
      this.scenario.setRangeFrom(_rangeFrom)
      range = _rangeFrom
      this.updateHorizonByRangeType(range)
    },
    scenarioType(_scenarioType) {
      let type = null
      if (_scenarioType instanceof Object) {
        type = _scenarioType.value
      } else {
        type = _scenarioType
      }
      this.setShowRange(type)
      this.updateHorizon(type, this.scenario.getScenario.rangeFrom)
      this.scenario.setType(type)
    },
    fromScenario(_fromScenario) {
      this.updateDates(_fromScenario.id)
      this.scenario.setFromScenario(_fromScenario)
    }
  },
  mounted() {
    this.scenario = scenarioStore()
    this.types = [
      {
        name: this.$t('application.pages.scenarioCargill.weekly'),
        value: scenarioTypes.weekly
      },
      {
        name: this.$t('application.pages.scenarioCargill.monthly'),
        value: scenarioTypes.monthly
      }
    ]
    this.setRangeFrom()
    this.setCopyFrom()
    this.setScenarioType()
    this.dateFormat = this.$t('application.dateFormat')

    if (this.scenarioType == scenarioTypes.weekly) {
      this.setWeeklyDatesRange()
    }
    if (this.scenarioType == scenarioTypes.monthly) {
      this.setMonthlyDatesRange()
    }
    this.setMonthlyDatesRange1()
    if (this.rangeFrom == 1) {
      this.setMonthlyDatesRange1()
    }
    if (this.rangeFrom == 2) {
      this.setMonthlyDatesRange()
    }
  },
  methods: {
    setCopyFrom() {
      if (this.scenario.getScenario.scenarioSource) {
        this.copyFrom = this.scenario.getScenario.scenarioSource
      } else {
        this.copyFrom = 'master'
      }
      if (this.scenario.getScenario.fromScenario) {
        this.fromScenario = this.scenario.getScenario.fromScenario
      }
    },
    setScenarioType() {
      if (this.scenario.getScenario.type) {
        this.scenarioType = this.scenario.getScenario.type
      } else {
        this.scenarioType = scenarioTypes.weekly
      }
    },
    setRangeFrom() {
      if (this.scenario.getScenario.rangeFrom) {
        this.rangeFrom = this.scenario.getScenario.rangeFrom
      } else {
        this.rangeFrom = '2'
      }
    },
    setMonthlyDatesRange() {
      this.start = moment()
        .startOf('month')
        .add(2, 'M')
        .format(this.dateFormatStore)
      this.end = moment(this.start)
        .add(22, 'M')
        .subtract(1, 'days')
        .format(this.dateFormatStore)
    },
    setMonthlyDatesRange1() {
      this.start = moment()
        .startOf('month')
        .add(1, 'M')
        .format(this.dateFormatStore)
      this.end = moment(this.start)
        .add(22, 'M')
        .subtract(1, 'days')
        .format(this.dateFormatStore)
    },
    setWeeklyDatesRange() {
      this.start = this.getStartDateNextWeek(
        moment().format(this.dateFormatStore)
      )
      this.end = moment(this.start)
        .add(8 * 7 - 1, 'days')
        .format(this.dateFormatStore)
    },
    getStartDateNextWeek(date) {
      let d = moment(date)
      let day = d.day()
      let diff = d.date() - day // adjust when day is sunday
      d = moment(d).set('date', diff)
      d = moment(d).add(7, 'days')
      return moment(d).format(this.dateFormatStore)
    },
    formatDate(date) {
      return moment(date).format(this.dateFormat)
    },
    getScenariosByType(_scenarioType) {
      service.getAll('filterQuery=type=' + _scenarioType).then((_scenarios) => {
        this.scenarios = _scenarios.sort((a, b) =>
          a.name.toLowerCase().localeCompare(b.name.toLowerCase())
        )
      })
    },
    updateHorizon(_scenarioType, _rangeFrom) {
      if (_scenarioType) {
        this.getScenariosByType(_scenarioType)

        if (_scenarioType == scenarioTypes.weekly) {
          this.setWeeklyDatesRange()
        }
        if (_scenarioType == scenarioTypes.monthly) {
          this.setRange = true
          if (_rangeFrom == 1) {
            this.setMonthlyDatesRange1()
          } else {
            this.setMonthlyDatesRange()
          }
          this.dateBaseMonthly = this.start
        }
      }
    },
    updateHorizonByRangeType(_rangeFrom) {
      this.start = this.dateBaseMonthly
      if (_rangeFrom == 1) {
        this.setMonthlyDatesRange1()
      } else {
        this.setMonthlyDatesRange()
      }
    },
    setShowRange(_scenarioType) {
      if (_scenarioType) {
        if (_scenarioType == scenarioTypes.monthly) {
          this.setRange = true
        } else {
          this.setRange = false
        }
      }
    },
    updateDates(scenarioId) {
      service.getAll('filterQuery=id=' + scenarioId).then((_scenarios) => {
        this.start = _scenarios[0].beginHorizon
        this.end = _scenarios[0].endHorizon
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.data-source-wrapper {
  display: flex;
  margin: 1rem 0;
  .data-source {
    display: flex;
    padding: 1rem 1rem 0 1rem;
    flex-direction: column;
    height: 76px;
    .v-input {
      height: inherit;
      margin-top: 0;
    }
    .select-datasource {
      color: #959db5;
      font-size: 0.8rem;
    }
    background: #30343f;
  }
}
.half-size {
  width: 49.5%;
  margin: 1rem 0.4rem !important;
}
.full-size {
  width: 100%;
  margin: 1rem 0.4rem !important;
}
.middle-margin {
  margin-left: 0.75%;
}
.content-label {
  margin-top: 0;
  i {
    color: #24bbef;
    font-size: 2rem;
  }
  .scenario {
    margin-left: 1rem;
    .name {
      color: #24bbef;
      font-size: 1rem;
    }
  }
}
</style>
