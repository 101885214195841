<template>
  <div v-if="scenario">
    <div class="content-label">
      <div>
        {{ $t('application.pages.scenarioCargill.confirmScenarioData') }}
      </div>
    </div>
    <bc-card class="mx-auto bg-header" style="margin: 1rem 0 1rem 0">
      <v-list-item>
        <v-list-item-content>
          <div
            class="confirm-content"
            v-for="(data, index) in scenarioData"
            :key="index"
          >
            <div class="labels attributes">
              {{ data.name }}
            </div>
            <div class="labels values">
              {{ data.value }}
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>
    </bc-card>
  </div>
</template>
<script>
import { scenarioStore } from '@cargill/shared'
import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'ScenarioNameStep',
  data() {
    return {
      scenario: null,
      scenarioData: []
    }
  },
  mounted() {
    const store = scenarioStore()
    this.scenario = store.getScenario
    this.scenarioData.push({
      name: this.$t('application.pages.scenarioCargill.scenarioName'),
      value: this.scenario.name
    })
    this.scenarioData.push({
      name: this.$t('application.pages.scenarioCargill.description'),
      value: this.scenario.description
    })
    this.scenarioData.push({
      name: this.$t('application.pages.scenarioCargill.scenarioPublic'),
      value: this.$t('application.general.' + this.scenario.public)
    })
    this.scenarioData.push({
      name: this.$t('application.pages.scenarioCargill.startDate'),
      value: moment(this.scenario.beginHorizon).format(
        this.$t('application.dateFormat')
      )
    })
    this.scenarioData.push({
      name: this.$t('application.pages.scenarioCargill.endDate'),
      value: moment(this.scenario.endHorizon).format(
        this.$t('application.dateFormat')
      )
    })
    this.scenarioData.push({
      name: this.$t('application.pages.scenarioCargill.type'),
      value: this.$t(
        'application.pages.scenarioCargill.' + _.camelCase(this.scenario.type)
      )
    })
    if (this.scenario.fromScenario) {
      this.scenarioData.push({
        name: this.$t('application.pages.scenarioCargill.dataSource'),
        value: `${this.$t('application.pages.scenarioCargill.copy')} - ${
          this.scenario.fromScenario.name
        }`
      })
    } else {
      this.scenarioData.push({
        name: this.$t('application.pages.scenarioCargill.dataSource'),
        value: this.$t(
          'application.pages.scenarioCargill.' + this.scenario.scenarioSource
        )
      })
    }
  },
  methods: {
    create() {
      /** beginHorizon: "2021-09-01T00:00:00"
      description: "TESTE"
      endHorizon: "2021-09-03T00:00:00"a
      name: "asdasdads"
      public: false
      type: "weekly"
      scenarioSource: 'Blank',
      fromScenario: ''
      */
    }
  }
}
</script>
<style lang="scss" scoped>
.confirm-content {
  display: flex;
  align-items: center;
}
.labels {
  color: #959db5;
  font: normal normal bold 14px/39px Aller;
  text-transform: uppercase;
  border-bottom: 1px solid #616575;
  &.attributes {
    width: 200px;
  }
  &.values {
    margin-left: 1rem;
    width: 100%;
    min-height: 40px;
  }
}

.capitalize {
  text-transform: capitalize;
}
i {
  font-size: 2rem;
  color: var(--bc-title-color);
}
</style>
