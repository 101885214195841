var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('bc-dialog',{attrs:{"value":_vm.show,"width":_vm.width,"persistent":_vm.persistent},on:{"input":_vm.onCancelHandler}},[_c('bc-card',{attrs:{"color":"medium-grey","height":_vm.height}},[_c('div',{staticClass:"confirm-header"},[_c('div',[(!_vm.persistent)?_c('bc-btn',{staticClass:"confirm-header__close-button",attrs:{"icon":"","small":""},on:{"click":_vm.onCancelHandler}},[_c('bc-icon',{staticClass:"confirm-header__close-icon"},[_vm._v("fa-times")])],1):_vm._e()],1)]),_c('div',{staticClass:"message-wrapper"},[_c('bc-message',{attrs:{"type":_vm.type,"icon":_vm.icon,"title":_vm.title,"subtitle":_vm.subtitle,"caption":_vm.caption,"actions":[
            {
              type: 'button',
              label: _vm._cancelLabel,
              color: _vm._cancelColor,
              callback: _vm.onCancelHandler
            },
            {
              type: 'button',
              label: _vm._confirmLabel,
              color: _vm._confirmColor,
              callback: _vm.onConfirmHandler
            }
          ]}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasCheckbox),expression:"hasCheckbox"}]},[_c('bc-checkbox',{attrs:{"label":_vm._askAgainLabel},on:{"change":_vm.onCheckChange},model:{value:(_vm.showAskAgain),callback:function ($$v) {_vm.showAskAgain=$$v},expression:"showAskAgain"}})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }