<template>
  <div>
    <div class="scenario-name-content">
      <v-icon>fas fa-layer-group</v-icon>
      <span>{{ $t('application.pages.scenarioCargill.title') }}</span>
      <div class="caption">
        {{ $t('application.pages.scenarioCargill.identifyScenario') }}
      </div>
    </div>
    <div class="text-inputs">
      <bc-text-field
        :label="$t('application.pages.scenarioCargill.scenarioName') + ' *'"
        v-model="name"
        filled
        :rules="rules.nameRules"
        counter
        maxlength="100"
        :error-messages="errorMessages"
        @input="$emit('clearErrors')"
      >
      </bc-text-field>

      <bc-text-field
        :label="$t('application.pages.scenarioCargill.description')"
        v-model="description"
        :value="description"
        filled
        :rules="rules.descriptionRules"
        counter
        maxlength="250"
      >
      </bc-text-field>
    </div>
    <div>
      <v-switch
        class="offset"
        v-model="isPublic"
        :label="$t('application.pages.scenarioCargill.public')"
      ></v-switch>
    </div>
  </div>
</template>
<script>
import { BcTextField } from '@brain/core'
import { scenarioStore } from '@cargill/shared'

export default {
  name: 'ScenarioNameStep',
  props: {
    errorMessages: {
      type: Array
    }
  },
  components: { BcTextField },
  watch: {
    name(_val) {
      this.scenario.setName(_val)
    },
    description(_val) {
      this.scenario.setDescription(_val)
    },
    isPublic(_val) {
      this.scenario.setPublic(_val)
    }
  },
  data() {
    return {
      name: '',
      description: '',
      step: 1,
      scenario: null,
      isPublic: true,
      rules: {
        nameRules: [(v) => v.length <= 100 || 'Max 100 characters'],
        descriptionRules: [(v) => v.length <= 250 || 'Max 250  characters']
      }
    }
  },
  mounted() {
    this.scenario = scenarioStore()
    this.scenario.setPublic(this.isPublic)

    if (this.scenario.getScenario.name) {
      this.name = this.scenario.getScenario.name
    }

    if (this.scenario.getScenario.description) {
      this.description = this.scenario.getScenario.description
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.offset {
  margin-left: 1rem;
}
.text-inputs {
  div {
    margin-bottom: 1rem !important;
  }
}
.scenario-name-content {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  text-transform: uppercase;
  margin: 3rem;
  span {
    color: #24bbef;
    margin: 1rem;
    font: normal normal bold 26px/31px Aller;
  }
  i {
    font-size: 4rem;
    color: #24bbef;
  }
  .caption {
    color: #959db5;
    font: normal normal normal 16px/19px Aller;
  }
}
</style>
